import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Reglamento de Canje de Puntos" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>

            <p>Ultima Actualización: 16 Noviembre, 2023</p>

            <p>Este reglamento establece las condiciones y pautas para el canje de puntos T-Rewards en el programa de lealtad de
              Viajes Travelero. Al participar en el canje de puntos, aceptas cumplir con los términos y condiciones descritos a continuación.
            </p>

            <h1>1. Elegibilidad</h1>
            <h2>1.1. Clientes Elegibles</h2>
            <p>
              Todos los clientes registrados en el programa T-Rewards con puntos acumulados son elegibles para el canje.
            </p>

            <h2>1.2. Requisito Mínimo de Puntos</h2>
            <p>Para realizar un canje, los clientes deben tener un saldo mínimo de 50 puntos en su cuenta T-Rewards.</p>

            <h1>2. Valor de Canje</h1>
            <h2>2.1. Tasa de Conversión</h2>
            <p>
              Cada 50 puntos T-Reward tiene un valor de 1 dólar estadounidense (USD) para el canje. Esta tasa de conversión
              puede estar sujeta a cambios y será anunciada previamente.
            </p>

            <h2>2.2. Redondeo</h2>
            <p>
              El valor de canje se redondeará al número entero más cercano.
            </p>

            <h1>3. Métodos de Canje</h1>
            <h2>3.1. Canje en Compras</h2>
            <p>
              Los puntos T-Rewards se pueden canjear al realizar compras en el sitio web de Viajes Travelero. Durante el proceso de pago,
              los clientes pueden optar por canjear una cantidad específica de puntos para reducir el costo de la compra.
            </p>

            <h2>3.2. Opciones de Canje</h2>
            <p>
              Los clientes pueden canjear puntos para obtener descuentos en productos, servicios o experiencias específicas ofrecidas
              por Viajes Travelero. Las opciones de canje estarán disponibles en el catálogo de recompensas del programa.
            </p>

            <h1>4. Restricciones y Limitaciones</h1>
            <h2>4.1. Canje Parcial</h2>
            <p>
              Los clientes tienen la opción de realizar canjes parciales, utilizando una parte de sus puntos y dejando el resto para futuras transacciones.
            </p>

            <h2>4.2. Fecha de Expiración</h2>
            <p>
              Los puntos T-Rewards tienen una validez de 12 meses desde la fecha de obtención. Después de este período,
              los puntos expirarán y no podrán ser canjeados.
            </p>

            <h1>5. Proceso de Canje</h1>
            <h2>5.1. Instrucciones de Canje</h2>
            <p>
              Los clientes pueden encontrar instrucciones detalladas sobre cómo realizar el canje en el sitio web de
              Viajes Travelero o a través de la atención al cliente.
            </p>

            <h2>5.2. Confirmación de Canje</h2>
            <p>
              Los clientes recibirán una confirmación por correo electrónico después de completar con éxito un canje de puntos.
            </p>

            <h1>6. Cambios en el Programa de Canje</h1>
            <h2>6.1. Modificaciones</h2>
            <p>
              Viajes Travelero se reserva el derecho de realizar cambios en las tasas de canje, las opciones de canje
              y otras condiciones relacionadas con el canje de puntos T-Rewards.
            </p>

            <h2>6.2. Notificación de Cambios</h2>
            <p>
              Los cambios significativos en las reglas de canje se comunicarán a los miembros a través de notificaciones en el
              sitio web o por correo electrónico.
            </p>

            <h1>7. Privacidad y Datos Personales</h1>
            <p>
              El canje de puntos está sujeto a la política de privacidad de Viajes Travelero. Consulta dicha política para obtener
              información detallada sobre el tratamiento de datos personales.
            </p>

            <h1>8. Disposiciones Generales</h1>
            <h2>8.1. Jurisdicción</h2>
            <p>
              Este reglamento se rige por las leyes del lugar de residencia del titular de la cuenta.
            </p>

            <h2>8.2. Resolución de Disputas</h2>
            <p>
              Cualquier disputa relacionada con el canje de puntos se resolverá de manera amistosa. En caso de desacuerdo,
              ambas partes acuerdan someterse a la jurisdicción de los tribunales competentes.
            </p>

            <h1>Contacto</h1>
            <p>Si tienes alguna duda sobre las condiciones de uso de T-Rewards, por favor contactanos:</p>

            <ul>
              <li>Correo: reservas@viajestravelero.com</li>
              <li>Telefono: 69203758</li>
            </ul>
            <br/>

            <h3>
              Viajes Travelero se reserva el derecho de modificar este reglamento en cualquier momento sin previo aviso. 
              Al participar en el programa T-Rewards, aceptas los términos y condiciones aquí establecidos.
            </h3>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      {/* <Footer /> */}
    </AnimationRevealPage>
  );
};
