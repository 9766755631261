import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Reglamento de Acumulación y Canje de Puntos para Paquetes Vacacionales ’TRAVELERO REWARDS’" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>

            <p>Fecha de entrada en vigor: 1 de enero de 2024</p>
            <h2>Consideraciones Generales: </h2>

            <p>
              Viajes Travelero es una empresa boliviana legalmente constituida y habilitada para el
              ejercicio del comercio, con oficinas en el eje troncal del país; La Paz, Cochabamba y Santa Cruz.
            </p>

            <h1>Artículo 1: Objetivo </h1>
            <p>
              El presente reglamento tiene como objetivo establecer las reglas para la acumulación y el canje de
              puntos ‘TRAVELERO REWARD’ por parte de nuestros clientes al adquirir paquetes vacacionales a través
              de Viajes Travelero.
            </p>

            <h1>Artículo 2: Registro en la Página Web</h1>
            <ul>
              <li>
                2.1. Para poder acumular y canjear puntos en el programa de lealtad ‘TRAVELERO REWARDS, los clientes deben
                registrarse en la página web de Viajes Travelero, ubicada en www.viajestravelero.com.
              </li>

              <li>
                2.2. El registro en la página web es gratuito y requiere que los clientes proporcionen información precisa 
                y completa, incluyendo su nombre, dirección de correo electrónico y otros datos necesarios para 
                identificar y comunicarse con el cliente.
              </li>

              <li>
                2.3. El acceso a la cuenta de lealtad y la acumulación de puntos ‘TRAVELERO REWARSD’ estarán disponibles 
                exclusivamente para los clientes registrados en la página web de Viajes Travelero.
              </li>

              <li>
                2.4. Los puntos acumulados serán asociados a la cuenta del cliente registrado y serán visibles en su 
                área de usuario en la página web.
              </li>

              <li>
                2.5. Viajes Travelero se compromete a mantener la confidencialidad de la información de registro de 
                los clientes y utilizarla únicamente con fines relacionados con el programa de lealtad y las reservas de viajes.
              </li>
            </ul>

            <h1>Artículo 3: Acumulación de Puntos </h1>
            <ul>
              <li>
                3.1. Por cada dólar gastado en la compra de un paquete vacacional, los clientes recibirán 1 punto en su 
                cuenta de lealtad.
              </li>

              <li>
                3.2. Los puntos acumulados se asignarán de acuerdo al monto total de la compra, excluyendo impuestos y 
                tarifas adicionales.
              </li>

              <li>
                3.3. Los puntos se acreditarán en la cuenta del cliente después de completar la compra y realizar el pago 
                correspondiente.
              </li>
            </ul>

            <h1>Artículo 4: Canje de Puntos </h1>
            <ul>
              <li>
                4.1. Los puntos acumulados pueden ser canjeados por descuentos en futuras compras de paquetes vacacionales o
                por la compra de boletos aéreos nacionales, dentro del territorio boliviano.
              </li>

              <li>
                4.2. Para canjear los puntos, los clientes deben tener un mínimo de 50 puntos en su cuenta.
              </li>

              <li>
                4.3. Cada 50 puntos acumulados equivalen a un dólar en descuento en una compra futura o compra de boletos 
                aéreos nacionales, dentro del territorio boliviano.
              </li>

              <li>
                4.4. El canje de puntos se realiza al momento de la compra de un nuevo paquete vacacional y se aplicará 
                como un descuento al monto total.
              </li>
            </ul>

            <h1>Artículo 5: Validez de los Puntos </h1>
            <ul>
              <li>
                5.1. Los puntos acumulados tendrán una validez de un año desde la fecha de la compra que generó los puntos.
              </li>

              <li>
                5.2. Pasada la fecha de vencimiento, los puntos serán considerados nulos y no podrán ser canjeados.
              </li>
            </ul>

            <h1>Artículo 6: Modificaciones al Reglamento </h1>
            <ul>
              <li>
                6.1. Viajes Travelero se reserva el derecho de modificar este reglamento en cualquier momento,
                lo que incluye la cantidad de puntos otorgados por dólar gastado, el valor de canje y la duración 
                de la validez de los puntos.
              </li>

              <li>
                6.2. Las modificaciones al reglamento serán comunicadas a los clientes a través de medios electrónicos 
                y se considerarán efectivas a partir de la fecha de dicha comunicación.
              </li>
            </ul>

            <h1>Artículo 7: Cumplimiento de las Reglas </h1>
            <p>
              El incumplimiento de las reglas del presente reglamento por parte de los clientes podría resultar en la pérdida
              de puntos acumulados o la suspensión del programa de lealtad.
            </p>

            <h1>Artículo 8: Contacto</h1>
            <p>
              Para cualquier pregunta o aclaración sobre el programa de lealtad, los clientes pueden ponerse en 
              contacto al número de WhatsApp 69203758.
            </p>
            <br />

            <h3>
              Este reglamento de acumulación y canje de puntos se establece para fomentar la lealtad de nuestros clientes y
              recompensarlos por su preferencia al Viajes Travelero para sus experiencias vacacionales. Los cambios en 
              el reglamento se harán con el objetivo de mejorar y optimizar el programa de lealtad.
            </h3>
            <br />

            <p>Aldo Rodríguez – Gerente General de Viajes Travelero</p>
            <p>Fanny Rosalva Adrián – Gerente de Operaciones Nacional de Viajes Travelero</p>
            <br />
            <p>Este reglamento entra en vigor a partir de la fecha de su firma.</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      {/* <Footer /> */}
    </AnimationRevealPage>
  );
};
