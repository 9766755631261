import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Política de Privacidad" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Ultima Actualización: 16 Noviembre, 2023</p>

            <p>
              La privacidad y seguridad de la información de nuestros clientes son fundamentales para Viajes Travelero.
              Esta Política de Privacidad describe cómo recopilamos, utilizamos, divulgamos y protegemos la información 
              personal de los usuarios del programa T-Rewards. Al participar en el programa T-Rewards, aceptas los términos 
              de esta política.
            </p>

            <h1>1. Información Recopilada</h1>
            <h2>1.1. Información del Usuario</h2>
            <p>
              Recopilamos información personal proporcionada por los usuarios durante el registro en el programa T-Rewards.
              Esto puede incluir nombre, dirección de correo electrónico, información de contacto y detalles de la cuenta.
            </p>

            <h2>1.2. Información de Transacciones</h2>
            <p>Al realizar transacciones, como acumular o canjear puntos, recopilamos detalles sobre esas transacciones.</p>

            <h2>1.3. Información Automática</h2>
            <p>
              Podemos recopilar información automáticamente, como direcciones IP, tipo de dispositivo, navegador web y 
              patrones de interacción con nuestro sitio.
            </p>

            <h1>2. Uso de la Información</h1>
            <h2>2.1. Procesamiento de Transacciones</h2>
            <p>
              Utilizamos la información para procesar transacciones relacionadas con el programa T-Rewards, incluido el 
              acumulado y canje de puntos.
            </p>

            <h2>2.2. Comunicaciones</h2>
            <p>
              Utilizamos la información de contacto para enviar comunicaciones relacionadas con el programa, como 
              notificaciones de puntos, ofertas especiales y actualizaciones.
            </p>

            <h2>2.3. Mejora de Servicios</h2>
            <p>
              Analizamos la información para mejorar nuestros servicios, personalizar la experiencia del usuario y 
              ofrecer contenido relevante.
            </p>

            <h1>3. Compartir Información</h1>
            <h2>3.1. Socios Comerciales</h2>
            <p>
              Podemos compartir información con socios comerciales para ofrecer beneficios adicionales a los miembros 
              del programa T-Rewards.
            </p>

            <h2>3.2. Requisitos Legales</h2>
            <p>
              Revelaremos información cuando sea requerido por la ley, en respuesta a procesos legales o para proteger 
              nuestros derechos legales.
            </p>

            <h1>4. Seguridad de la Información</h1>
            <h2>4.1. Medidas de Seguridad</h2>
            <p>
              Implementamos medidas de seguridad para proteger la información contra accesos no autorizados, 
              alteraciones, divulgaciones o destrucciones no autorizadas.
            </p>

            <h2>4.2. Acceso Restringido</h2>
            <p>
              Limitamos el acceso a la información personal a empleados y socios que necesitan conocer esa información 
              para operar, desarrollar o mejorar nuestros servicios.
            </p>

            <h1>5. Cookies y Tecnologías Similares</h1>
            <h2>5.1. Uso de Cookies</h2>
            <p>
              Utilizamos cookies y tecnologías similares para mejorar la experiencia del usuario, recopilar información 
              y realizar análisis.
            </p>

            <h2>5.2. Control de Cookies</h2>
            <p>
              Los usuarios pueden controlar el uso de cookies a través de la configuración de su navegador.
              La desactivación de cookies puede afectar la funcionalidad del programa T-Rewards.
            </p>

            <h1>6. Cambios en la Política de Privacidad</h1>
            <h2>6.1. Actualizaciones</h2>
            <p>
              Actualizaremos esta Política de Privacidad según sea necesario. Se indicará la fecha de la última
              actualización en la parte superior del documento.
            </p>

            <h2>6.2. Notificación de Cambios</h2>
            <p>
              Comunicaremos cambios significativos a través de nuestro sitio web o por otros medios apropiados.
            </p>

            <h1>7. Información de Contacto</h1>
            <h2>7.1. Preguntas y Solicitudes</h2>
            <p>
              Los usuarios pueden ponerse en contacto con nosotros en cualquier momento para realizar preguntas o solicitudes 
              relacionadas con esta Política de Privacidad.
            </p>
            <h1>Contacto</h1>
            <p>Si tienes alguna duda sobre las condiciones de uso de T-Rewards, por favor contactanos:</p>

            <ul>
              <li>Correo: reservas@viajestravelero.com</li>
              <li>Telefono: 69203758</li>
            </ul>
            <br/>

            <h3>
            Viajes Travelero se compromete a proteger la privacidad de sus usuarios y a cumplir con las leyes de 
            protección de datos aplicables. Al participar en el programa T-Rewards, aceptas las prácticas descritas 
            en esta Política de Privacidad.
            </h3>


          </Text>
        </ContentWithPaddingXl>
      </Container>
      {/* <Footer /> */}
    </AnimationRevealPage>
  );
};
